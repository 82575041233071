import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Footer, Container, Columns } from 'react-bulma-components'

function TemplateWrapper({ children }) {
  return (
    <div>
      {children}
      <Footer className="no-print">
        <Container>
          <Columns className="content is-size-6">
            <Columns.Column className="is-5 is-offset-1">
              <h4>Contact</h4>
              <p>
                Pocketworks Mobile Ltd
                <br />
                AHQ, 10-12 East Parade
                <br />
                Leeds, LS1 2BH
              </p>
              <p>
                <a href="tel:0113 466 0302">0113 466 0302</a>
                <br />
                <a href="mailto:hello@pocketworks.co.uk">
                  hello@pocketworks.co.uk
                </a>
              </p>
              <p>
                <a href="https://twitter.com/thepocketworks">
                  <span className="icon is-large has-text-dark">
                    <i className="fab fa-twitter fa-2x" />
                  </span>
                </a>
                <a href="https://www.linkedin.com/company/pocketworks-mobile-ltd">
                  <span className="icon is-large has-text-dark">
                    <i className="fab fa-linkedin-in fa-2x" />
                  </span>
                </a>
                <a href="https://dribbble.com/pocketworks">
                  <span className="icon is-large has-text-dark">
                    <i className="fab fa-dribbble fa-2x" />
                  </span>
                </a>
                <a href="https://github.com/pocketworks">
                  <span className="icon is-large has-text-dark">
                    <i className="fab fa-github fa-2x" />
                  </span>
                </a>
                <a href="https://www.instagram.com/pocketworks/">
                  <span className="icon is-large has-text-dark">
                    <i className="fab fa-instagram fa-2x" />
                  </span>
                </a>
                <a href="https://www.pocketworks.shop/">
                  <span className="icon is-large has-text-dark">
                    <i className="fa-brands fa-shopify fa-2x" />
                  </span>
                </a>
              </p>
              <p>
                <span className="icon has-text-dark">
                  <i className="fas fa-copyright" />
                </span>{' '}
                {new Date().getFullYear()} Pocketworks Mobile Ltd
              </p>

              <p>
                Pocketworks is a UK registered company no.{' '}
                <a href="https://beta.companieshouse.gov.uk/company/08041956">
                  08041956
                </a>
                . VAT registration 136641417.
              </p>

              <p className="">
                Buy beautiful&nbsp;
                <span className="has-text-dark icon">
                  <i className="fa-solid fa-shirt fa-1x" />
                </span>
                <span className="has-text-dark icon">
                  <i className="fa-solid fa-mug-hot fa-1x" />
                </span>
                <span className="has-text-dark icon">
                  <i className="fa-solid fa-socks fa-1x" />
                </span>
                &nbsp;from{' '}
                <a href="https://pocketworks.shop" className="is-underlined">
                  our online shop
                </a>
              </p>

              <p>
                ★★★★★ (4.9) on{' '}
                <a
                  href="https://clutch.co/profile/pocketworks"
                  target="_blank"
                  rel="noreferrer"
                >
                  Clutch, Top App Developers UK
                </a>
              </p>

              <div
                className="clutch-widget has-text-centered"
                data-url="https://widget.clutch.co"
                data-widget-type="2"
                data-height="45"
                data-clutchcompany-id="92574"
              />

              <div
                className="is-hidden"
                itemScope
                itemType="https://schema.org/Organization"
              >
                <span itemProp="name">Pocketworks Mobile Ltd</span>
                <br />
                <div
                  itemProp="address"
                  itemScope
                  itemType="https://schema.org/PostalAddress"
                >
                  <span itemProp="streetAddress">
                    Monarch House, Queen Street
                  </span>
                  <br />
                  <span itemProp="addressLocality">Leeds</span>
                  <br />
                  <span itemProp="addressRegion">West Yorkshire</span>
                  <span itemProp="postalCode">LS1 2TW</span>
                </div>
                Phone: <span itemProp="telephone">0113 466 0302</span>
              </div>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h4>Services</h4>
              <ul>
                <li>
                  <Link to="/services/advisory/digital-possibilities">
                    Digital Possibilities
                  </Link>
                </li>
                <li>
                  <Link to="/services/advisory/concept-design">
                    Concept Design
                  </Link>
                </li>
                <li>
                  <Link to="/services/advisory/growth-diagnostic">
                    Growth Diagnostic
                  </Link>
                </li>
                <li>
                  <Link to="/services/advisory/business-case-help">
                    Business Case Help
                  </Link>
                </li>
                <li>
                  <Link to="/services/mobile-app-development">
                    App Development
                  </Link>
                </li>
              </ul>
              <h4>Work</h4>
              <ul>
                <li>
                  <Link to="/work">Case Studies</Link>
                </li>
                <li>
                  <Link to="/work/workforce-productivity">Apps for Work</Link>
                </li>
                <li>
                  <Link to="/work/digital-transformation">
                    Digital Transformation
                  </Link>
                </li>
                <li>
                  <Link to="/work/device-manufacturers">
                    IoT, Devices &amp; Instruments
                  </Link>
                </li>
                <li>
                  <Link to="/work/tech-for-good-startups">
                    Startups &amp; Tech for Good
                  </Link>
                </li>
              </ul>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h4>Company</h4>
              <ul>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/about/story">About</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/about/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact/nda">NDA</Link>
                </li>
                <li>
                  <Link to="/about/technologies">Our Tech Stack</Link>
                </li>
                <li>
                  <Link to="/mobile-technology-radar">Mobile Tech Radar</Link>
                </li>
              </ul>
            </Columns.Column>
          </Columns>
        </Container>
      </Footer>

      {/* <script type="application/ld+json">
      {
        "@context" : "https://schema.org",
        "@type" : "Organization",
        "name" : "Pocketworks Mobile Ltd",
      "url" : "https://pocketworks.co.uk",
      "sameAs" : [
        "https://twitter.com/thepocketworks",
        "https://www.linkedin.com/company/pocketworks-mobile-ltd",
        "https://www.facebook.com/thepocketworks/",
        "https://dribbble.com/pocketworks",
        "https://www.foursquare.com/v/pocketworks/5089511ee4b09b0239d29174",              
        "https://www.instagram.com/pocketworks/"
        ],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "123 Example Street",
          "addressRegion": "CA",
          "postalCode": "94040",
          "addressCountry": "US"
        }
      }
      </script> */}
    </div>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default TemplateWrapper

import PropTypes from 'prop-types'
import React from 'react'
import { Hero, Container, Columns } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import { GatsbyImage } from 'gatsby-plugin-image'

function HeroComponent({ caseStudy }) {
  return (
    <Hero className="has-bg-blue is-medium" id="case-hero">
      <span class="circle is-top-left" />
      <Hero.Header>
        <SiteNav className="is-dark" fullLogo />
      </Hero.Header>
      <Container>
        <Hero.Body className="">
          <Container className="content">
            <Columns>
              <Columns.Column
                className={`${
                  caseStudy.cardAppImage ? '' : 'is-hidden'
                } is-3-fullhd is-4-widescreen is-3-desktop is-3-tablet is-hidden-mobile`}
              >
                {caseStudy.cardAppImage ? (
                  <GatsbyImage
                    image={caseStudy.cardAppImage.gatsbyImageData}
                    alt={caseStudy.client.companyName || 'Pocketworks Client'}
                    className="phone-image"
                  />
                ) : null}
              </Columns.Column>
              <Columns.Column className="is-9-fullhd is-8-widescreen is-9-desktop is-9-tablet is-12-mobile">
                <GatsbyImage
                  image={caseStudy.client.logoLight.gatsbyImageData}
                  alt={caseStudy.client.companyName || 'Pocketworks Client'}
                  style={{ width: '150px' }}
                />
                <h1
                  className="title mt-2"
                  dangerouslySetInnerHTML={{ __html: caseStudy.title }}
                />
                <p className="">CASE STUDY</p>
              </Columns.Column>
            </Columns>
          </Container>
        </Hero.Body>
      </Container>
    </Hero>
  )
}

HeroComponent.propTypes = {
  caseStudy: PropTypes.object,
}

export default HeroComponent

import PropTypes from 'prop-types'
import React from 'react'

// Components
import Layout from 'components/layout'

// Page Components
import Hero from './components/hero'
import Body from './components/body'

function CaseStudy({ data: { caseStudy } }) {
  return (
    <Layout>
      <div className="case-study">
        <Hero caseStudy={caseStudy} />
        <Body caseStudy={caseStudy} />
      </div>
    </Layout>
  )
}

export default CaseStudy

CaseStudy.propTypes = {
  data: PropTypes.object.isRequired,
}

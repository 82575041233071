import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Section, Container, Columns } from 'react-bulma-components'

function Body({ caseStudy }) {
  return (
    <Section>
      <Container className="content">
        <Columns className="is-centered">
          <Columns.Column className="is-6-desktop is-four-fifths-tablet pt-6 mt-6">
            <div
              className="clearfix"
              dangerouslySetInnerHTML={{
                __html: caseStudy.bodyNode.childMarkdownRemark.html,
              }}
            />
          </Columns.Column>
        </Columns>

        <Columns className="is-centered mt-6">
          <Columns.Column className="is-7-desktop is-four-fifths-tablet">
            <div className="card classy has-bg-orange mt-6">
              <span class="square" />
              <h2>Hire Pocketworks to help you create impact and growth</h2>
              <p>
                We're app developers and mobile growth advisors. We help you
                bring digital products to market that drive positive impact,
                customer growth and revenue.
              </p>
              <p>
                <Link className="button is-medium is-dark" to="/services">
                  View our services
                </Link>
              </p>
            </div>
            <p>
              <Link className="button is-secondary mt-3" to="/work">
                &larr; Back to case studies
              </Link>
            </p>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

Body.propTypes = {
  caseStudy: PropTypes.object,
}

export default Body

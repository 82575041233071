/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import CaseStudy from 'routes/templates/case-study'

// Meta Component
import Meta from 'components/Meta'

// Services Route
export default CaseStudy

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.caseStudy.seoMetaTags.tags} hideMeta />
}

export const query = graphql`
  query CaseStudyQuery($slug: String!) {
    caseStudy: datoCmsCasestudy(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      content
      heroBannerImage {
        url
        fluid(maxWidth: 1024, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_tracedSVG
        }
      }
      bodyNode {
        childMarkdownRemark {
          html
          excerpt
        }
      }
      cardAppImage {
        url
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { fm: "png", auto: "compress", maxW: 800 }
        )
      }
      outcomes {
        outcomeValue
        summary
      }
      client {
        companyName
        logo {
          url
          gatsbyImageData(
            placeholder: TRACED_SVG
            imgixParams: { fm: "png", auto: "compress", maxW: 1024 }
          )
        }
        logoLight {
          url
          gatsbyImageData(
            placeholder: TRACED_SVG
            imgixParams: {
              fm: "png"
              auto: "compress"
              monochrome: "000000"
              maxW: 1024
              bri: -100
            }
          )
        }
      }
    }
  }
`

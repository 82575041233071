import PropTypes from 'prop-types'
import React from 'react'

function Meta({ tags, children, hideTitle, hideMeta }) {
  return (
    <>
      {tags.map((tag, index) => {
        // console.log(tag.attributes ? tag.attributes : '')

        if (tag.tagName === 'title') {
          if (!hideTitle) {
            return <title key={index}>{tag.content}</title>
          }
          return null
        }
        if (tag.attributes.name === 'description') {
          if (!hideMeta) {
            return (
              <meta
                name="description"
                content={tag.attributes.content}
                key={index}
              />
            )
          }
          return null
        }
        if (tag.attributes.name === 'og:image') {
          return (
            <meta
              name="image"
              property={tag.attributes.name}
              content={tag.attributes.content}
              key={index}
            />
          )
        }
        return (
          <meta
            name={tag.attributes.name}
            property={tag.attributes.property}
            content={tag.attributes.content}
            key={index}
          />
        )
      })}
      {children}
    </>
  )
}

Meta.propTypes = {
  tags: PropTypes.array.isRequired,
  children: PropTypes.array,
  hideTitle: PropTypes.bool,
  hideMeta: PropTypes.bool,
}

export default Meta
